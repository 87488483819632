<ion-header>
    <ion-toolbar class="apptitle">
    </ion-toolbar>
    <ion-toolbar color="primary">
        <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{"Select Category" | translate}}
        </ion-title>
        <ion-buttons slot="end">
            <ion-button class="cart-icon-nav" (click)="navcart()">
                <ion-icon name="cart-outline"></ion-icon> 
                <ion-badge class="carts" color="danger">{{noOfItems}}</ion-badge>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-grid>
      <ion-row>
          <!-- first row -->
          <ion-slides *ngIf="ComingData.length > 0" loop="true" autoplay="3000" speed="1000" pager="true">
              <ion-slide *ngFor="let item of ComingData">
                  <div class="overlay">
                      <img src="{{item.thumb}}" class="slider-img">
                  </div>
                  <div class="card-title">{{"Featured Product" | translate}}</div>
              </ion-slide>
          </ion-slides>
      </ion-row>
      <!-- first row -->
      
      <!-- second-row -->

      <h2 class="text-center">{{"Categories" | translate}}</h2>

      <ion-row>
          <ion-col size="6" *ngFor="let item of Categories" (click)="navigate(item.$key)">
              <div style="text-align:center">
                  <cl-image [attr.alt]="item.title" public-id="{{item.thumb }}" secure="true" class="thumbnail inline" style="width: 100%;">
                      <cl-transformation height="200" width="300" crop="fill" radius="20"></cl-transformation>
                  </cl-image>
                  <span>{{item.title}}</span>
                  <p></p>
              </div>
          </ion-col>
      </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-toolbar>
      <ion-title>
          <ion-icon name="navigate" alt="navigate"></ion-icon>
          <span *ngIf="locationbased">Browsing within {{searchrange}} {{searchrangeunit}}</span>
          <span *ngIf="!locationbased">All sellers</span>
      </ion-title>
  </ion-toolbar>
</ion-footer>
