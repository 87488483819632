import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';

@Pipe({
    name: 'FireBaseImageUrl'
})
export class FireBaseImageUrlPipe implements PipeTransform {
    constructor(private storage: AngularFireStorage) {}

    transform(value: string): Observable<any> {
        return this.storage.ref(value).getDownloadURL();
            /*.(function(url) {
                return url;
            })
            .catch(function(err) {
                return 'error.jpg';
            });*/
    }
}
